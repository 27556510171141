/* eslint-disable */

import Vue from 'vue'
import axios from 'axios'

// BRIME.JS INJECTION
// Methods defined here will be available on any view by calling this.FUNCTION()

const ENVIRONMENT = window.location.hostname
let API_PREFIX = ''
if (ENVIRONMENT === 'localhost') {
  API_PREFIX = 'http://localhost:3000/v1'
} else if (ENVIRONMENT === 'staging.brime.tv') {
  API_PREFIX = 'https://api-staging.brime.tv/v1'
} else {
  API_PREFIX = 'https://api.brime.tv/v1'
} 

axios.defaults.baseURL = API_PREFIX

Vue.mixin({
  methods: {
    //  Account Methods
    async uploadUserAvatar(content) {
      const formData = new FormData()
      formData.append('avatar', content)
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const streamUpdate = await axios.post('/account/avatar', formData, config)
      return streamUpdate.data
    },
    async subCheck(channel) {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const subCheckLookup = await axios.get(`/account/sub_check/${channel}`, config)
      return subCheckLookup.data
    },
    async helloNextLogin() {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const ssoLogin = await axios.get('/integrations/hellonext/login', config)
      return ssoLogin.data
    },
    // Channel Methods
    async createClip(channel, title, start, end) {
      const body = {
        channel,
        title,
        start,
        end,
      }
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const clipCall = await axios.post('/clip/create', body, config)
      return clipCall.data
    },
    async uploadOfflineImage(content) {
      const formData = new FormData()
      formData.append('avatar', content)
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const streamUpdate = await axios.post('/channels/offline_image', formData, config)
      return streamUpdate.data
    },
    async channelUpdateStream(title, category) {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const streamUpdate = await axios.post('/channels/stream', { title, category }, config)
      return streamUpdate.data
    },
    async getChatLinks() {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const subCheckLookup = await axios.get('/chat/integrations', config)
      return subCheckLookup.data
    },
    async getChatLinksNew() {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const subCheckLookup = await axios.get('/channels/chat_links', config)
      return subCheckLookup.data
    },
    async clipLookup(xid) {
      const clipLookup = await axios.get(`/clip/${xid}`)
      return clipLookup.data
    },
    async getTime() {
      const timeLookup = await axios.get(`https://time.brime.tv`)
      return timeLookup.data
    },
    async getVod(xid) {
      const vodLookup = await axios.get(`/vods/${xid}`)
      return vodLookup.data
    },
    async getVods(channel_xid) {
      const vodLookup = await axios.get(`/vods/channel/${channel_xid}`)
      return vodLookup.data
    },
    async getBroadcasts(channelXID) {
      const timeLookup = await axios.get(`/channel/${channelXID}/broadcasts`)
      return timeLookup.data
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
    async channelClips(xid) {
      const clipLookup = await axios.get(`/clip/channel/${xid}`)
      return clipLookup.data
    },
    async donateCheck(channel) {
      const clipLookup = await axios.get(`integrations/streamlabs/donate_check/${channel}`)
      return clipLookup.data
    },
    async chatBotCheck(channel) {
      const clipLookup = await axios.get(`chatbot/${channel}/bot_name`)
      return clipLookup.data
    },
    async chatBotCommandsLookup(channel) {
      const commandsLookup = await axios.get(`chatbot/${channel}/commands`)
      return commandsLookup.data
    },
    async chatBotUpdate(name) {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const chatBotUpdate = await axios.post('chatbot/bot_name', { name }, config)
      return chatBotUpdate.data
    },
    async chatBotDelete() {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const chatBotDelete = await axios.delete('chatbot/bot_name', config)
      return chatBotDelete.data
    },
    async deleteBotCommand(command_xid) {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const chatBotDelete = await axios.delete(`chatbot/command/${command_xid}`, config)
      return chatBotDelete.data
    },
    async putCommand(command, response) {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const putRequest = await axios.put(`chatbot/command`, {command, response} ,config)
      return putRequest.data
    },
    async updateCommand(xid, command, response) {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const putRequest = await axios.post(`chatbot/command`, {xid, command, response} ,config)
      return putRequest.data
    },
    async chatLink(username) {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const chatLinkUpdate = await axios.post('/chat/integrations', { username }, config)
      return chatLinkUpdate.data
    },
    async donateToggle(state) {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const chatLinkUpdate = await axios.post(`/integrations/streamlabs/donate/${state}`, {},config)
      return chatLinkUpdate.data
    },
    async enroll2FA() {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const chatLinkUpdate = await axios.get(`/account/2fa/enroll`,config)
      return chatLinkUpdate.data
    },
    async getDonationSettings() {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const chatLinkUpdate = await axios.get(`/integrations/donations/settings`,config)
      return chatLinkUpdate.data
    },
    async getChannelDonate(channel_xid) {
      const chatLinkUpdate = await axios.get(`/integrations/donations/channel_settings/${channel_xid}`)
      return chatLinkUpdate.data
    },
    async addDonationIntegration(platform, username) {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const chatLinkUpdate = await axios.post(`/integrations/donations/enable`, {platform, username},config)
      return chatLinkUpdate.data
    },
    async deleteDonationIntegration(platform) {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const chatLinkUpdate = await axios.post(`/integrations/donations/disable`, {platform},config)
      return chatLinkUpdate.data
    },
    async list2FA() {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const chatLinkUpdate = await axios.get(`/account/2fa/list`,config)
      return chatLinkUpdate.data
    },
    async testSlobsAlerts(type) {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const chatLinkUpdate = await axios.get(`/integrations/streamlabs/alert/${type}`, config)
      return chatLinkUpdate.data
    },
    async linkStreamlabs(slobsToken) {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const linkSlobs = await axios.post('/integrations/streamlabs/link', { token: slobsToken }, config)
      return linkSlobs.data
    },
    async disconnectStreamlabs() {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const deleteSlobs = await axios.delete('/integrations/streamlabs/link', config)
      return deleteSlobs.data
    },
    async checkSlobs() {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const linkSlobs = await axios.get('/integrations/streamlabs/check', config)
      return linkSlobs.data
    },
    async channelLookupBySlug(slug) {
      const channelLookup = await axios.get(`/channels/slug/${slug}`)
      return channelLookup.data
    },
    async channelLookupByXID(xid) {
      const channelLookup = await axios.get(`/channels/${xid}`)
      return channelLookup.data
    },
    async channelLookupByLegacy(id) {
      const channelLookup = await axios.get(`/channels/slug/${slug}`)
      return channelLookup.data
    },
    async chatPerms(channel) {
      console.log(channel)
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const permsLookup = await axios.post(`/chat/perms`, { channel }, config)
      return permsLookup.data
    },
    async channelChatters(slug) {
      const channelLookup = await axios.get(`/chat/channel/${slug}/chatters`)
      return channelLookup.data.chatters
    },
    async channelFollowerCount(xid) {
      const channelLookup = await axios.get(`/channels/${xid}/follower_count`)
      return channelLookup.data.data.follower_count
    },
    async banCheck(xid) {
      const banLookup = await axios.get(`/channels/bandata/${xid}`)
      return banLookup.data
    },
    // Category Methods
    async categoryLookupBySlug(slug) {
      const channelLookup = await axios.get(`/categories/slug/${slug}`)
      return channelLookup.data
    },
    async categorySearch(term) {
      const channelLookup = await axios.get(`/categories/search/${term}`)
      return channelLookup.data
    },
    async getLiveCategories() {
      const categoryLookup = await axios.get('/categories/live')
      return categoryLookup.data.data.live_categories
    },
    // Chat Methods
    async getViewercount(xid) {
      const viewersLookup = await axios.get(`/chat/channel/${xid}/chatters`)
      return viewersLookup.data.count
    },
    // Restreaming Methods
    async getRepubs() {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const repubLookup = await axios.get('/multistreaming/repubs', config)
      return repubLookup.data
    },
     // Get Billing Invoices
     async getInvoices() {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const invoiceLookup = await axios.get('/subs/invoices', config)
      return invoiceLookup.data.invoices
    },
      // Get Billing Invoice PDF
      async getPDFInvoice(id) {
        const token = await this.$auth.getTokenSilently()
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        const invoiceLookup = await axios.get(`/subs/invoice_pdf/${id}`, config)
        return invoiceLookup.data
      },
    async createRepub(platform, streamKey) {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const streamUpdate = await axios.post(`/multistreaming/${platform}`, { streamKey }, config)
      return streamUpdate.data
    },
    async toggleVods(enabled) {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const vodToggle = await axios.post(`/vods/toggle`, { vods_enabled: enabled }, config)
      return vodToggle.data
    },
    async updateRepub(platform, enabled, streamKey) {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const streamUpdate = await axios.put(`/multistreaming/${platform}`, { streamKey, enabled }, config)
      return streamUpdate.data
    },
    async updateMuiltichat(platform, enabled, value) {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const streamUpdate = await axios.post(`/multichat/${platform}`, { enabled, value }, config)
      return streamUpdate.data
    },
    async deleteRepub(platform) {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const repubLookup = await axios.delete(`/multistreaming/${platform}`, config)
      return repubLookup.data
    },
    async raidChannel(channel) {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const langLookup = await axios.post('/raid/send', {destination: channel},config)
      return langLookup.data
    },
    // User Methods
    async getChatLang() {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const langLookup = await axios.get('/account/chat_lang', config)
      return langLookup.data
    },
    async updateChatLang(lang) {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const setChatLang = await axios.post('/account/chat_lang', { language: lang }, config)
      return setChatLang.data
    },
    async userSetDisplayname(displayname) {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const setDisplayname = await axios.post('/account/displayname', { displayname }, config)
      return setDisplayname.data
    },
    async userFollowCheck(xid) {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const followQuery = await axios.get(`/channels/${xid}/is_following`, config)
      return followQuery.data.is_following
    },
    async userGetFollowing() {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const followQuery = await axios.get('/account/following?meta=true&live=true', config)
      return followQuery.data
    },
    async followChannel(xid) {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const followQuery = await axios.post(`/channels/${xid}/is_following`, {}, config)
      return followQuery.data
    },
    async unfollowChannel(xid) {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const followQuery = await axios.delete(`/channels/${xid}/is_following`, config)
      return followQuery.data
    },
    async getUser() {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const userData = await axios.get('/account/me', config)
      return userData.data
    },
    async getStreamKey() {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const userData = await axios.get('/account/streamkey', config)
      return userData.data.stream_key
    },
    async getSubs() {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const subData = await axios.get('/subs/list', config)
      return subData.data
    },
    async refreshStreamKey() {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const userData = await axios.post('/account/streamkey/refresh', {}, config)
      return userData.data
    },
    async updateChatColor(color) {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const userData = await axios.post('/account/chat_color', { color }, config)
      return userData.data
    },
    async getChatColor() {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const userData = await axios.get('/account/chat_color', config)
      return userData.data
    },
    async getDevApps() {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const userData = await axios.get('/developers/apps', config)
      return userData.data
    },
    async getPayoutLinks() {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const userData = await axios.get('/payouts/iframes', config)
      return userData.data
    },
  },
})
